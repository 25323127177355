/** @jsxImportSource theme-ui */
import { Button, Container } from 'theme-ui'
import { Box } from 'theme-ui'
import { Styled } from '~/components/styled'
import Link from 'next/link'
import { withPageProps } from '~/api/services'
import { GetStaticProps } from 'next'
import dynamic from 'next/dynamic'
import SEO from '~/components/SEO'
const PenguinChandelierLoader = dynamic(
  () =>
    import('src/components/Penguin-Chandelier-Loader').then(
      (r) => r.PenguinChandelierLoader
    ),
  {
    ssr: false,
  }
)

export default function NotFound() {
  return (
    <Box
      sx={{
        py: 6,
        minHeight: '70vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <SEO title="404 - Page not found" />
      <Container>
        <Styled.h1>Oops!</Styled.h1>
        <Styled.p>
          Looks like this page {"doesn't"} exist. {"Let's"} get you back to the
          home screen.
        </Styled.p>
        <Box>
          <Link href="/">
            <Button variant="primary">Take me home</Button>
          </Link>
        </Box>
        <PenguinChandelierLoader />
      </Container>
    </Box>
  )
}
export const getStaticProps = (async () => {
  return {
    props: await withPageProps({
      headerProps: {
        sticky: true,
      },
    }),
  }
}) satisfies GetStaticProps
